import { common } from './common'
export const pages = {
  AddCalendarPage: {
    title: common.register,
    calendarSelectionBox: {
      title: 'Select a calendar to link to Spir',
      nextStep: common.proceedToRegister,
      description:
        'After adding a calendar, you will be able to your calendar events alongside appointments slots in the same view.',
      securityPageLink: '',
      previousStep: 'Back to Sign up'
    },
    modal: {
      common: {
        continueButton: 'Continue',
        registerButton: common.proceedToRegister,
        reasonTitle: 'Why do we need access?',
        reasonText: 'We need to view and edit your calendar in order to show your availability and add new events.'
      },
      google: {
        title: 'Check all the boxes',
        introduction: 'Spir needs your permission to access your calendar.'
      },
      microsoft: {
        title: 'Select all permissions',
        introduction: 'Spir needs your permission to access your calendar.'
      }
    }
  },
  AuthTeamsCreate: {
    calendarSelectMerit: {
      title: 'Your team calendar',
      description: 'Connect a calendar for your team to add and manage events.',
      nextStep: 'Connect'
    },
    title: 'Get started with your team',
    pageDescription:
      'Select an account / create a new account and fill in the following fields to start using Spir immediately.',
    teamSettingSection: {
      form: {
        companyName: {
          label: 'Company',
          formName: 'a company name',
          placeHolder: 'Acme Inc'
        },
        teamName: {
          label: 'Team name',
          formName: 'a team name',
          placeHolder: 'Recruiting',
          help: 'Name it after your company, project, or anything else.'
        },
        contactPersonName: {
          label: 'Your name',
          formName: 'your name',
          placeHolder: 'John Doe'
        },
        phoneNumber: {
          label: 'Phone',
          formName: 'a phone number'
        },
        updatableInfo: 'This can be changed later.'
      }
    },
    registrationCautionSection: {
      others: common.registrationCaution.others,
      othersUrl: common.registrationCaution.othersUrl
    },
    confirmButton: 'Create team',
    error: {
      calendar: 'Calendar could not be registered',
      unknown: 'Team could not be created',
      emailProvider: 'Team cannot be created by accounts registered with email'
    },
    useDefaultBrowser: {
      messageHtml:
        "You can't <strong>sign up to team plan</strong> from this browser.</br>Sign up from a supported browser such as Safari or Chrome."
    }
  },
  AuthTeamIdStart: {
    head: {
      title: 'Welcome to your team',
      completion: 'Welcome to Spir for Teams. We’re glad you joined us.'
    },
    onboardingSteps: {
      head: {
        title: 'Get the most out of your team',
        desc: 'Spir works the best when your entire team is onboard. Get started by inviting people to your team.'
      },
      steps: {
        '1': {
          title: 'Get everyone onboard',
          desc: 'Invite your coworkers to share their calendars'
        },
        '2': {
          title: 'Choose what to share',
          desc: 'Customize privacy settings for each calendar you add'
        },
        '3': {
          title: 'Start scheduling as a team',
          desc: 'Schedule using templates and one-time events'
        }
      },
      helpPage: {
        title: 'Your guide to Spir for Teams',
        desc: 'Everything you need to know to start using your team.',
        detailPage: 'Read the guide',
        detailLink: common.links.teamHelpPage,
        imageAlt: 'Everything you need to know to start using your team.'
      }
    },
    nextStep: {
      start: 'Go to your team'
    }
  },
  AuthTeamIdStartFree: {
    head: {
      title: 'Welcome to your team',
      completion: 'Welcome to Spir for Teams. We’re glad you joined us.'
    },
    onboardingSteps: {
      head: {
        title: 'Get the most out of your team',
        desc: 'Spir works the best when your entire team is onboard. Get started by inviting people to your team.'
      },
      steps: {
        '1': {
          title: 'Get everyone onboard',
          desc: 'Invite your coworkers to share their calendars'
        },
        '2': {
          title: 'Choose what to share',
          desc: 'Customize privacy settings for each calendar you add'
        },
        '3': {
          title: 'Start scheduling as a team',
          desc: 'Schedule using templates and one-time events'
        }
      },
      helpPage: {
        title: 'Your guide to Spir for Teams',
        desc: 'Everything you need to know to start using your team.',
        detailPage: 'Read the guide',
        detailLink: common.links.teamHelpPage,
        imageAlt: 'Everything you need to know to start using your team.'
      }
    },
    nextStep: {
      start: 'Go to your team'
    }
  },
  PersonalScheduleConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    },
    confirmerAction: {
      title: common.noAvailableConfirmationDate
    }
  },
  PersonalAvailabilitySharingConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    }
  },
  TeamScheduleConfirmGuest: {
    modal: {
      title: common.overlayYourCalendar
    },
    subscriptionFailure: common.teamRestricted
  },
  TeamAvailabilitySharingConfirmGuest: {
    title: 'Select a date and time',
    signUpModalTitle: common.overlayYourCalendar,
    subscriptionErr: common.teamRestricted
  },
  TeamAvailabilitySharingConfirm: {
    subscriptionErr: common.teamRestricted
  },
  PersonalGroupPollVoteGuest: {
    titleInListView: 'Select convenient times for you',
    signupGuideBoxTitle: 'Select convenient times for you',
    signUpModalTitle: common.overlayYourCalendar,
    signUpButton: common.checkCandidatesOnCalendar
  },
  AfterConfirmation: {
    buttons: {
      cancel: common.cancel
    },
    scheduleCancel: {
      title: 'Are you sure you want to cancel?',
      description: 'This action cannot be undone. A cancellation email will be sent to you and the organizer.'
    },
    scheduleAlternativeDates: {
      requestAlternative: 'Request alternative dates'
    },
    subscriptionFailure: common.teamRestricted
  },
  PersonalScheduleSuggestNewSlots: {
    footer: {
      confirmButton: common.confirm,
      cancelButton: common.cancel
    }
  },
  AvailabilityList: {
    title: 'Templates',
    createButton: common.availabilitySharingCreateButton,
    messages: {
      successes: {
        duplicated: 'Template duplicated'
      },
      errors: {
        exceedMaxPublishedCount: "You can't have more than {count} templates in public access",
        notSubscriptionUser: "You can't create more than {count} templates",
        disconnectedZoomIntegration: 'Zoom is disconnected'
      }
    }
  },
  TeamMemberSetting: {
    title: 'Members',
    invite: 'Invite',
    listTitle: 'Members',
    invitationTip: 'Choose a plan', // TODO: プラン制限 英訳
    currentTeamMembersCount: common.currentTeamMembersCount,
    currentTeamMembersCounts: common.currentTeamMembersCounts,
    canceledCurrentTeamMembersCount: common.currentTeamMembersCount,
    canceledCurrentTeamMembersCounts: common.currentTeamMembersCounts,
    invitationHelp: 'Invite more people from the top-right button.',
    functionExplanation: `Share and manage each other's schedules with Spir for Teams.\nYou can invite up to <span class="member-count">{planMemberLimit} members</span> in the {planName}.`,
    shareAndManage: `Share and manage each other's schedules with Spir for Teams.`,
    reachMemberLimit: 'Your team have reached the maximum number of members. Upgrade your plan to invite more members.',
    message: {
      errors: {
        EMAIL_ALREADY_INVITED: 'User already invited',
        ETC: 'Unknown error',
        INVITATION_ID_NOT_FOUND: 'Could not copy invitation link'
      },
      successInvite: 'You have been invited',
      successCancel: 'Canceled'
    },
    team: {
      member: common.member
    },
    member: {
      'delete-confirm-left-data':
        '{availabilityCount} templates and {scheduleCount} unconfirmed events organized by this member will be deleted.',
      'delete-confirm': "You can't undo this action.",
      'delete-label': 'Remove this member?'
    },
    'member-is-managing-resource': {
      deleteMember: {
        header: 'This member is managing a resource',
        description: 'Please change who manages the resource in resources settings before removing this user.'
      },
      changeRoleToMember: {
        header: 'This member is managing a resource',
        description: 'Please change who manages the resource in resources settings before changing the user role.'
      },
      button: 'Manage resources',
      close: 'Close'
    }
  },
  TeamPlanSetting: {
    title: common.plan,
    selectionDescription: 'Choose the right plan for your team.',
    confirmationSucceeded: common.planConfirmationSucceeded,
    alertBoxText: {
      trialWithoutSetup: 'Subscribe by {dueDate} to continue using the Team Plan',
      canceling: 'Your Team Plan will expire at {dueDate}',
      canceled: 'Choose a plan to start using Spir for Teams',
      pastDue:
        'Your payment was unsuccessful. Please update your payment method in the <span class="payment-info-page">billing page</span>.',
      paymentFailed:
        'Your Team Plan has expired because your payment was unsuccessful. Subscribe to start using it again.'
    },
    planHint: {
      active: 'Your next billing date is {nextDate}.',
      initial:
        "Try it free for 1 month. After the free trial, you'll be billed the monthly rate for the selected plan on the next billing date."
    },
    cardDeclined: 'Your card was declined'
  },
  TeamPlanOrderConfirmation: {
    registerTitle: 'Get started with the {planName} plan',
    selectedPlan: 'Selected plan',
    fillInCreditCardInfo: 'Payment method',
    creditCardFormTitle: 'Pay by credit card',
    billingSection: 'Summary',
    backButton: 'Change plan',
    confirmButton: 'Subscribe',
    confirmationSucceeded: common.planConfirmationSucceeded,
    stripe: {
      cardGenericDecline: 'Card declined. Contact the card issuer for details.',
      cardInsufficientFunds: 'Your card has insufficient funds',
      expiredCard: 'Your card has expired',
      incorrectCvc: 'Incorrect security code',
      processingError: 'An error has occurred. Try again.',
      noMatched: 'Card registration unsuccessful',
      setupIntentAuthenticationFailure: 'Card authentication unsuccessful. Please try with a different card.',
      formGenerationFailed: 'Could not generate payment form. Try again.'
    },
    billingNote: {
      freeTrialPeriod: "Currently in free trial. You'll be billed the monthly rate starting on {nextDate}.",
      nextDate: 'You will be billed on {nextDate}.'
    },
    billTable: {
      planPrice: 'Monthly rate',
      salesTax: 'Sales tax',
      sum: 'Total',
      price: '{amount} JPY'
    }
  },
  ServiceUnavailable: {
    title: 'Event not found',
    unAvailableDescription:
      'This event might have been deleted by the organizer.</br>Contact the organizer for details.',
    goToTopPage: 'Go to calendar'
  },
  TeamMemberInvite: {
    subscriptionErr: common.teamRestricted
  },
  AvailabilitySharingCreate: {
    subscriptionErr: common.teamRestricted
  },
  ConfirmedList: {
    title: common.nav.confirmedList,
    error: {
      confirmedItemNotFound: 'No further information could be found'
    },
    pastEventsReloadBtn: 'Reload past events'
  },
  UnconfirmedListPage: {
    title: common.nav.unconfirmedList
  },
  ListQuestionnaires: {
    newQuestionnaire: {
      title: 'Untitled form'
    },
    QuestionnaireList: {
      TeamQuestionnairePreviewPanel: {
        cancel: {
          title: 'Close'
        }
      }
    },
    toasts: {
      success: {
        created: 'Form created.'
      },
      failed: {
        notMember: common.questionnaires.toasts.failed.notMember,
        teamNotAvailable: common.questionnaires.toasts.failed.teamNotAvailable
      }
    }
  },
  TeamQuestionnaireDetail: {
    deleteQuestionConfirmationModal: {
      title: 'Remove this question?',
      description:
        'If this question is being used to customize event titles for a template, the titles will be reset to default.\nAre you sure you want to continue?'
    },
    editQuestionToNotRequiredConfirmationModal: {
      title: 'Make question optional',
      description:
        'If this question is being used to customize event titles for a template, the titles will be reset to default. Are you sure you want to continue?',
      confirmBtn: 'Save'
    },
    toasts: {
      success: {
        deleteQuestion: 'Question deleted.',
        editQuestion: 'Question saved.'
      },
      failed: {
        notMember: common.questionnaires.toasts.failed.notMember,
        teamNotAvailable: common.questionnaires.toasts.failed.teamNotAvailable,
        questionnaireNotFound: common.questionnaires.toasts.failed.questionnaireNotFound,
        questionNotFound: 'Could not find question.',
        failedInChangeOrder: 'Could not sort form.',
        questionnaireValidation: 'Your input is invalid.',
        invalidError: 'Your input is invalid.'
      }
    },
    defaultQuestions: common.questionnaires.defaultQuestions,
    checkPreview: 'Preview',
    editQuestionnaireOpen: {
      title: 'Edit private info'
    },
    changeQuestionsOrderPanelOpen: {
      title: 'Reorder'
    },
    newQuestionOpen: {
      title: 'Add question'
    },
    preview: {
      title: 'Preview'
    },
    ReturnButton: {
      title: 'Forms'
    },
    EditQuestionnairePanel: {
      title: 'Private information',
      questionnaire: {
        title: 'Private title',
        description: 'Private description'
      },
      footerBox: {
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    ChangeQuestionsOrderPanel: {
      title: 'Reorder questions',
      footerBox: {
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    NewQuestionPanel: {
      title: 'Add a question',
      defaultPlaceholder: 'Enter text',
      defaultOption: 'Option 1',
      footerBox: {
        cancel: 'Cancel',
        save: 'Add'
      }
    },
    EditCustomQuestionPanel: {
      title: 'Edit a question',
      footerBox: {
        danger: 'Delete question',
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    EditDefaultQuestionPanel: {
      title: 'Edit a question',
      footerBox: {
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    TeamQuestionnairePreviewPanel: {
      cancel: {
        title: 'Close'
      }
    }
  },
  TeamWebhookSetting: {
    title: 'Webhook settings',
    description: 'Use the form below to register a webhook with your URL.',
    buttonName: 'Register'
  },
  TeamLayout: {
    pageNames: {
      availabilityList: common.nav.availabilityList,
      confirmedList: common.nav.confirmedList,
      unconfirmedList: common.nav.unconfirmedList,
      calendar: common.calendar,
      forms: 'Forms',
      teamSettings: 'Team settings' // TODO: UI_REVAMP STAGE 2 (翻訳依頼)
    }
  },
  PersonalHome: {
    createButton: common.availabilitySharingCreateButton
  },
  TeamHome: {
    createButton: common.availabilitySharingCreateButton
  },
  PersonalUnconfirmedList: {
    createButton: 'Create event'
  },
  TeamUnconfirmedList: {
    createButton: 'Create event',
    teamNotAvailable: 'Could not access team.'
  },
  ProfileView: {
    title: 'Profile'
  },
  ProfileShareView: {
    title: 'Profile'
  },
  ProfileEdit: {
    title: 'Profile'
  },
  Settings: {
    title: 'Account settings'
  },
  TeamAvailabilitySharingConfirmedEventEditByMember: {
    cannotEditSlot: "You can't edit time slots here"
  }
}
