import { FrontSupportLanguage } from '@/types'
import { FrontSupportStartWeekDay } from '@/types/frontSupportStartWeekDay'
import { ProfileResponse } from '@spirinc/contracts'

export type TimeZoneInfo = {
  key: string
  displayName?: string
}
export type TimeZones = {
  primary: TimeZoneInfo
  secondary?: TimeZoneInfo
  tertiary?: TimeZoneInfo
}

export enum WeekDay {
  monday = 1,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}

export type UserProfilePayload = {
  id?: string
  headLine?: string
  fullName?: string
  photoURL: string
  backgroundPhotoURL: string
  introduction: string
  companyName: string
  jobTitle: string
  timeZones: TimeZones
  availabilitySharingMaxCount: number
  availabilitySharingMaxPublishedCount: number
  startWeekDay: FrontSupportStartWeekDay
  email: string
  language: FrontSupportLanguage
  arrangementSettings?: ProfileResponse['arrangementSettings']
}

export class UserInfoModel {
  id?: string
  headLine?: string
  fullName?: string
  photoURL: string
  backgroundPhotoURL: string
  introduction: string
  companyName: string
  jobTitle: string
  timeZones: TimeZones
  availabilitySharingMaxCount: number
  availabilitySharingMaxPublishedCount: number
  startWeekDay: FrontSupportStartWeekDay
  email: string
  language: FrontSupportLanguage
  arrangementSettings?: ProfileResponse['arrangementSettings']
  constructor(data: UserProfilePayload) {
    this.id = data.id
    this.headLine = data.headLine
    this.fullName = data.fullName
    this.photoURL = data.photoURL
    this.backgroundPhotoURL = data.backgroundPhotoURL
    this.introduction = data.introduction
    this.companyName = data.companyName
    this.jobTitle = data.jobTitle
    this.timeZones = data.timeZones
    this.availabilitySharingMaxCount = data.availabilitySharingMaxCount
    this.availabilitySharingMaxPublishedCount = data.availabilitySharingMaxPublishedCount
    this.startWeekDay = data.startWeekDay
    this.email = data.email
    this.language = data.language
    this.arrangementSettings = data.arrangementSettings
  }
  get primaryTimezoneKey() {
    return this.timeZones.primary.key
  }
  get needToRequestUpdateProfile() {
    return !this.fullName || (!this.companyName && !this.jobTitle && !this.introduction)
  }
  asParameter() {
    return {
      headLine: this.headLine,
      fullName: this.fullName,
      photoURL: this.photoURL,
      backgroundPhotoURL: this.backgroundPhotoURL,
      introduction: this.introduction,
      companyName: this.companyName,
      jobTitle: this.jobTitle,
      timeZones: this.timeZones
    }
  }
}
