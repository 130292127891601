import { render, staticRenderFns } from "./InvalidateQueriesListener.vue?vue&type=template&id=2c25e731&scoped=true&"
import script from "./InvalidateQueriesListener.vue?vue&type=script&lang=ts&"
export * from "./InvalidateQueriesListener.vue?vue&type=script&lang=ts&"
import style0 from "./InvalidateQueriesListener.vue?vue&type=style&index=0&id=2c25e731&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_o6euipub4hyss22wijilcc5zs4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c25e731",
  null
  
)

export default component.exports