import { render, staticRenderFns } from "./AuthButtonsFrame.vue?vue&type=template&id=21cc46df&scoped=true&"
import script from "./AuthButtonsFrame.vue?vue&type=script&lang=ts&"
export * from "./AuthButtonsFrame.vue?vue&type=script&lang=ts&"
import style0 from "./AuthButtonsFrame.vue?vue&type=style&index=0&id=21cc46df&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_o6euipub4hyss22wijilcc5zs4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21cc46df",
  null
  
)

export default component.exports