import { previewSlots } from '@/infrastructures/apis/teamAvailabilitySharing'
import * as availabilityAPI from '@/lib/api/availabilityTeam'
import { SubscriptionCanceledInEditError } from '@/lib/sdk/error'
import { isTeamSubscriptionCanceledError } from '@/lib/utils/teamPlan'
import { Timezone } from '@/models'
import { AvailabilityModelForTeam, AvailabilitySummaryModelForTeam, PatternCandidateModel } from '@/models/data'
import { LanguageCode } from '@/models/language'
import store from '@/store'
import { FullCalendarEvent, Holiday } from '@/types'
import { cloneDeep } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { AvailabilitySharingListModule } from './availabilitySharingList'

const MODULE_NAME = 'EditAvailabilityTeam'

// const savedAvailability = new AvailabilityStorage()
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EditAvailabilityTeam extends VuexModule {
  _editModel: AvailabilityModelForTeam | null = null
  _patternCandidate: PatternCandidateModel | null = null
  _isLoading = false
  _isDirty = false

  get getIsLoading() {
    return this._isLoading
  }
  get getModel(): AvailabilityModelForTeam {
    // @ts-expect-error TS2322
    return this._editModel
  }
  get isDirty() {
    return this._isDirty
  }
  get candidatesByMerged(): FullCalendarEvent[] {
    if (!this._patternCandidate) {
      return []
    }
    return this._patternCandidate.mergedCandidates
  }
  get showMemberCalendars(): string[] | undefined {
    return this.getModel?.showMemberCalendars
  }
  @Action
  newAvailabilityForTeam({
    teamId,
    language,
    timezone
  }: {
    teamId: string
    language?: LanguageCode
    timezone?: Timezone
  }) {
    const newModel = new AvailabilityModelForTeam(teamId)
    if (language === 'ja' && timezone?.key === 'Asia/Tokyo') {
      newModel.countries = [{ code: 'JP' }]
    }
    this.SET_MODEL(newModel)
  }
  @Action({ commit: 'SET_MODEL' })
  async fetchAvailability({ teamId, id }: { teamId: string; id: string }) {
    this.SET_LOADING(true)
    try {
      const availability = await availabilityAPI.getAvailability(teamId, id)
      const newModel = new AvailabilityModelForTeam(teamId, availability)
      return newModel
    } catch (e) {
      if (isTeamSubscriptionCanceledError(e)) throw new SubscriptionCanceledInEditError()
      throw e
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action({ commit: 'SET_CANDIDATES' })
  async fetchCandidatesByParam() {
    if (!this.getModel.isValidToFetchCandidates) {
      return Promise.resolve(null)
    }
    try {
      if (this.getModel.memberIds.length === 0) return null
      const response = await previewSlots(this.getModel.teamId, this.getModel.parameterForPreviewSlots)
      this.SET_HOLIDAYS(response.holidays)
      return {
        ...response,
        candidates: response.slots
      }
    } catch (e) {
      return null
    }
  }
  @Action
  async create(): Promise<string> {
    try {
      this.SET_LOADING(true)
      // @ts-expect-error TS2531
      const response = await availabilityAPI.create(this._editModel.teamId, this._editModel.parameterForCreate)
      // @ts-expect-error TS2531
      const model = new AvailabilityModelForTeam(this._editModel.teamId, response)
      AvailabilitySharingListModule.addNewTeamAvailabilitySharing({
        // @ts-expect-error TS2531
        teamId: this._editModel.teamId,
        teamAvailabilitySharing: model
      })
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async update(): Promise<string> {
    try {
      this.SET_LOADING(true)
      const response = await availabilityAPI.updatePattern(
        // @ts-expect-error TS2531
        this._editModel.teamId,
        // @ts-expect-error TS2531
        this._editModel.id,
        // @ts-expect-error TS2531
        this._editModel.parameterForEdit
      )
      const newModel = AvailabilitySummaryModelForTeam.createFromAvailabilityModelForTeam(
        // @ts-expect-error TS2531
        new AvailabilityModelForTeam(this._editModel.teamId, response)
      )
      AvailabilitySharingListModule.UPDATE_TEAM_LIST({
        // @ts-expect-error TS2531
        teamId: this._editModel.teamId,
        id: newModel.id,
        newModel
      })
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  updateModel(newModel: AvailabilityModelForTeam) {
    newModel.updateEndDate()
    this.SET_MODEL(newModel)
    this.SET_DIRTY(true)
  }
  @Action
  reset() {
    this.SET_MODEL(null)
    this.SET_CANDIDATES(null)
    this.SET_DIRTY(false)
  }
  @Mutation
  SET_DIRTY(dirtyFlag: boolean) {
    this._isDirty = dirtyFlag
  }
  @Mutation
  SET_MODEL(model: AvailabilityModelForTeam | null) {
    this._editModel = cloneDeep(model)
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
  @Mutation
  SET_CANDIDATES(patternCandidate) {
    if (patternCandidate) {
      this._patternCandidate = new PatternCandidateModel(patternCandidate)
    } else {
      this._patternCandidate = null
    }
  }
  @Mutation
  SET_HOLIDAYS(holidays: Holiday[]) {
    if (this._editModel) {
      const newObject = cloneDeep(this._editModel)
      newObject.holidays = holidays
      this._editModel = newObject
    }
  }
}

export default getModule(EditAvailabilityTeam)
