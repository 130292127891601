import {
  cancelInvitation,
  deleteMyCalendar,
  deleteTeamMember,
  fetchInvitationUrl,
  getTeam,
  getTeamMemberSelf,
  invitationTeamMember,
  resendInvitation,
  updateMyCalendar,
  updateTeamInfo,
  updateTeamMemberAuthority
} from '@/lib/api/team'
import { EVENTS, EventBus } from '@/lib/eventBus'
import { invitationTeamMember as sdkInvitationMember } from '@/lib/sdk/team'
import { TeamDetailModel, TeamMemberSelfModel } from '@/models/data/team'
import { TeamId } from '@/models/team'
import store from '@/store'
import teamCalendarModule from '@/store/modules/teamCalendar'
import UserModule from '@/store/modules/user'
import { MemberResponse } from '@spirinc/contracts'
import { QueryClient } from '@tanstack/vue-query'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { TeamPlanStateModule } from './teamSubscriptionState'

const MODULE_NAME = 'TeamRecord'

export type TeamInfo = {
  team: TeamDetailModel | null
  myInfo: TeamMemberSelfModel | null
  isLoading: boolean
}

type TeamRecordProps = {
  [teamId: string]: TeamInfo
}

const invalidateFetchTeamQueries = (teamId: string) => {
  EventBus.emit(EVENTS.INVALIDATE_QUERIES, { queryKey: [`/teams/${teamId}`] })
  EventBus.emit(EVENTS.INVALIDATE_QUERIES, { queryKey: [`/teams/${teamId}/members/self`] })
}

export interface IModuleTeamRecord {
  _teamRecord: TeamRecord
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TeamRecord extends VuexModule {
  _teamRecord: TeamRecordProps = {}

  get teamByTeamId() {
    return (teamId: string): TeamInfo => {
      return this._teamRecord[teamId]
    }
  }
  get myInfoByTeamId() {
    return (teamId: string): TeamMemberSelfModel => {
      // @ts-expect-error TS2322
      return this._teamRecord[teamId]?.myInfo
    }
  }
  get memberInfo() {
    return (teamId: string, memberId: string): MemberResponse => {
      const team = this.teamByTeamId(teamId)
      if (!team) {
        // @ts-expect-error TS2322
        return null
      }
      // @ts-expect-error TS2322
      return team.team?.members.find(m => m.id === memberId)
    }
  }

  get isAdmin(): (teamId: string) => boolean {
    return (teamId: string): boolean => {
      return this.myInfoByTeamId(teamId)?.authority === 'administrator'
    }
  }

  get trialEndDate(): (teamId: string) => Date | undefined {
    return (teamId: string): Date | undefined => {
      return this.teamByTeamId(teamId)?.team?.trialEndDate
    }
  }

  get isLoading() {
    return (teamId: string) => {
      const hit = this._teamRecord[teamId]
      if (!hit) {
        return false
      }
      return hit.isLoading
    }
  }
  get allTeamMembers() {
    return Object.keys(this._teamRecord)
      .filter(tr => TeamPlanStateModule.usable(tr))
      .map(tr => {
        return (this._teamRecord[tr].team?.members ?? []).map(tm => tm)
      })
      .flat()
  }
  @Mutation
  RESET_STATE() {
    this._teamRecord = {}
  }
  @Mutation
  SET_LOADING({ teamId, isLoading }: { teamId: string; isLoading: boolean }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        isLoading
      }
    }
  }

  // Must not use this method directly, use updateTeam action instead.
  @Mutation
  SET_TEAM({ teamId, team }: { teamId: string; team: TeamDetailModel }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        team: team
      }
    }
  }

  @Mutation
  SET_TEAM_MY_INFO({ teamId, myInfo }: { teamId: string; myInfo: TeamMemberSelfModel }) {
    this._teamRecord = {
      ...this._teamRecord,
      [teamId]: {
        ...this._teamRecord[teamId],
        myInfo: myInfo
      }
    }
  }

  @Action
  updateTeam({ teamId, team }: { teamId: TeamId; team: TeamDetailModel }) {
    this.SET_TEAM({ teamId, team })
    TeamPlanStateModule.save({ teamId, plan: team.plan })
  }

  @Action
  async fetchTeam({
    teamId,
    showLoader = true,
    staleTime = 0,
    queryClient
  }: {
    teamId: string
    showLoader?: boolean
    staleTime?: number
    queryClient?: QueryClient
  }) {
    if (!UserModule.isSignIn) {
      return
    }
    this.SET_LOADING({ teamId, isLoading: !!showLoader })
    try {
      const response =
        // this is for tests because queryClient causes tests to fail and because it is not supported Vue2.6 perfectly
        queryClient === undefined
          ? await Promise.all([getTeam(teamId), getTeamMemberSelf(teamId)])
          : await Promise.all([
              queryClient.fetchQuery({
                queryKey: [`/teams/${teamId}`],
                queryFn: async () => {
                  return await getTeam(teamId)
                },
                staleTime,
                gcTime: 1000 * 60 * 5 > staleTime ? 1000 * 60 * 5 : staleTime * 2 // Defaultの5分より長いstaleTimeが必要なケースでは、gcTimeも長めにしておく（一旦2倍）
              }),
              queryClient.fetchQuery({
                queryKey: [`/teams/${teamId}/members/self`],
                queryFn: async () => {
                  return await getTeamMemberSelf(teamId)
                },
                staleTime,
                gcTime: 1000 * 60 * 5 > staleTime ? 1000 * 60 * 5 : staleTime * 2
              })
            ])
      const teamModel = new TeamDetailModel(response[0])
      this.updateTeam({ teamId, team: teamModel })
      const myInfo = new TeamMemberSelfModel(response[1])
      this.SET_TEAM_MY_INFO({ teamId, myInfo })
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING({ teamId, isLoading: false })
    }
  }

  @Action
  async invitationTeamMember({ teamId, emails }: { teamId: string; emails: string[] }) {
    this.SET_LOADING({ teamId, isLoading: true })
    try {
      await Promise.all(emails.map(email => invitationTeamMember(teamId, { email })))
      invalidateFetchTeamQueries(teamId)
      await this.fetchTeam({ teamId, showLoader: false })
    } catch (err: any) {
      throw new Error(err)
    } finally {
      this.SET_LOADING({ teamId, isLoading: false })
    }
  }
  @Action
  invitationMember({ teamId, email }: { teamId: string; email: string }) {
    const result = sdkInvitationMember(teamId, { email })
    invalidateFetchTeamQueries(teamId)
    return result
  }
  @Action
  async cancelInvitation({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    await cancelInvitation(teamId, invitationId)
    invalidateFetchTeamQueries(teamId)
    this.fetchTeam({ teamId, showLoader: false })
  }
  @Action
  async resendInvitation({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    await resendInvitation(teamId, invitationId)
    invalidateFetchTeamQueries(teamId)
    this.fetchTeam({ teamId, showLoader: false })
  }
  @Action
  async updateCalendar({
    teamId,
    accountId,
    calendarId,
    payload
  }: {
    teamId: string
    accountId: string
    calendarId: string
    payload: { primary?: boolean; visibility?: 'readAll' | 'freeBusyOnly' }
  }) {
    const myInfo = await updateMyCalendar(teamId, accountId, calendarId, payload)
    invalidateFetchTeamQueries(teamId)
    this.SET_TEAM_MY_INFO({ teamId, myInfo })
  }
  @Action
  async deleteCalendar({ teamId, accountId, calendarId }: { teamId: string; accountId: string; calendarId: string }) {
    const myInfo = await deleteMyCalendar(teamId, accountId, calendarId)
    invalidateFetchTeamQueries(teamId)
    this.SET_TEAM_MY_INFO({ teamId, myInfo })
  }
  @Action
  async updateTeamInfo(data: {
    teamId: string
    companyName?: string
    name?: string
    contactPersonName: string
    phoneNumber: string
    photoUrl?: string
  }) {
    const { teamId, companyName, contactPersonName, name, phoneNumber, photoUrl } = data
    // @ts-expect-error TS2322
    await updateTeamInfo(teamId, { name, photoUrl, companyName, contactPersonName, phoneNumber })
    invalidateFetchTeamQueries(teamId)
    await this.fetchTeam({ teamId })
  }

  @Action
  async updateTeamMemberAuthority({
    teamId,
    memberId,
    authority
  }: {
    teamId: string
    memberId: string
    authority: string
  }) {
    await updateTeamMemberAuthority(teamId, memberId, authority)
  }

  @Action
  async fetchInvitationUrl({ teamId, invitationId }: { teamId: string; invitationId: string }) {
    return fetchInvitationUrl(teamId, invitationId)
  }
  @Action
  async deleteTeamMember({ teamId, memberId }: { teamId: string; memberId: string }) {
    await deleteTeamMember(teamId, memberId)

    const team = this._teamRecord[teamId].team
    // @ts-expect-error TS2531
    team.members = this._teamRecord[teamId].team.members.filter(member => member.id !== memberId)
    // @ts-expect-error TS2322
    this.updateTeam({ teamId, team })

    const teamCalendars = teamCalendarModule.teamCalendars
    const index = teamCalendarModule._teamCalendars.findIndex(calendar => calendar.id === teamId)
    const updatedCalendar = teamCalendars[index].memberCalendars.filter(calendar => calendar.id !== memberId)
    teamCalendars[index].memberCalendars = updatedCalendar
    teamCalendarModule.SET_TEAM_CALENDARS(teamCalendars)
  }
}

export default getModule(TeamRecord)
