import { render, staticRenderFns } from "./SpirDropdownSection.vue?vue&type=template&id=ca941780&scoped=true&"
import script from "./SpirDropdownSection.vue?vue&type=script&lang=ts&"
export * from "./SpirDropdownSection.vue?vue&type=script&lang=ts&"
import style0 from "./SpirDropdownSection.vue?vue&type=style&index=0&id=ca941780&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_o6euipub4hyss22wijilcc5zs4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca941780",
  null
  
)

export default component.exports