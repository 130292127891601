import { TeamDetailModel, TeamMemberSelfModel } from '@/models/data/team'
import { DateTime } from 'luxon'
import userflow from 'userflow.js'
import { spirDateFormat } from '../utils/dateFormat'

class UserFlowService {
  sendSelectedJoiningTeamInfo(team: TeamDetailModel, myInfo: TeamMemberSelfModel) {
    if (!userflow.isIdentified()) {
      return
    }
    const subscriptionEndDateMinusOneDay =
      team.plan.type === 'paid'
        ? DateTime.fromJSDate(team.plan.state.subscription.endDate).minus({ day: 1 }).toJSDate()
        : null
    userflow.group(
      team.id,
      {
        team_invited_member_count: team.invitedMemberCount,
        team_plan_type: team.plan.type,
        team_plan_status: team.plan.type === 'paid' ? team.plan.state.status : 'free',
        team_plan_subscription_end_date:
          team.plan.type === 'paid' ? team.plan.state.subscription.endDate.toISOString() : null,
        team_plan_subscription_end_date_formatted:
          team.plan.type === 'paid' ? spirDateFormat(team.plan.state.subscription.endDate, 'nennTsukiNichi') : null,
        team_plan_subscription_end_date_minus_one_day: subscriptionEndDateMinusOneDay?.toISOString(),
        team_plan_subscription_end_date_minus_one_day_formatted: subscriptionEndDateMinusOneDay
          ? spirDateFormat(subscriptionEndDateMinusOneDay, 'nennTsukiNichi')
          : null,
        team_plan_canceled_reason:
          team.plan.type === 'paid' && team.plan.state.status == 'canceled' ? team.plan.state.reason : null
      },
      {
        membership: {
          member_authority: myInfo.authority
        }
      }
    )
  }

  sendTeamAvailabilitySharingCount(teamId: string, count: number) {
    if (!userflow.isIdentified()) {
      return
    }
    userflow.group(teamId, { team_availability_sharing_count: count })
  }
}

export const userFlowService = new UserFlowService()
