export const SpirEnv = {
  local: 'local',
  development: 'development',
  development2: 'development2',
  staging: 'staging',
  production: 'production'
} as const

export type SpirEnv = keyof typeof SpirEnv

export function isProduction(): boolean {
  return SpirEnv.production === process.env.VUE_APP_MODE
}

export function isStaging(): boolean {
  return SpirEnv.staging === process.env.VUE_APP_MODE
}

export function isProductionLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.production, SpirEnv.staging])
}

export function isAllowedByEnv(allowedEnvs: SpirEnv[]): boolean {
  return allowedEnvs.some(env => env === process.env.VUE_APP_MODE)
}

export function isDevelopmentLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.local, SpirEnv.development, SpirEnv.development2])
}

const REACT_TEAM_CREATE_PAGE_IS_OPEN = 'react_team_create_page_is_open' as const
const reactTeamCreatePageIsOpenValue = 'r-t-c-p-i-o' as const

export function isReactTeamCreatePageIsOpen(): boolean {
  return (
    isDevelopmentLevelEnv() && localStorage.getItem(REACT_TEAM_CREATE_PAGE_IS_OPEN) === reactTeamCreatePageIsOpenValue
  )
}
export function enableReactTeamCreatePage(): void {
  localStorage.setItem(REACT_TEAM_CREATE_PAGE_IS_OPEN, reactTeamCreatePageIsOpenValue)
}
export function disableReactTeamCreatePage(): void {
  localStorage.removeItem(REACT_TEAM_CREATE_PAGE_IS_OPEN)
}

const BEFORE_AFTER_BUFFER_FORM_IS_OPEN = 'before_after_buffer_form_is_open' as const
const beforeAfterBufferFormIsOpen = 'b-a-b-f-i-o' as const

export function isBeforeAfterBufferFormOpen(): boolean {
  return (
    isDevelopmentLevelEnv() && localStorage.getItem(BEFORE_AFTER_BUFFER_FORM_IS_OPEN) === beforeAfterBufferFormIsOpen
  )
}
export function enableBeforeAfterBufferForm(): void {
  localStorage.setItem(BEFORE_AFTER_BUFFER_FORM_IS_OPEN, beforeAfterBufferFormIsOpen)
}
export function disableBeforeAfterBufferForm(): void {
  localStorage.removeItem(BEFORE_AFTER_BUFFER_FORM_IS_OPEN)
}

// TODO: プライシング Phase 1 リリース時に削除
const PRICING_PHASE_1_TEXT_CHANGE_IS_OPEN = 'pricing_phase_1_text_change_is_open' as const
const pricingPhase1TextChangeIsOpen = 'p-p-1-t-c-i-o' as const

export function isPricingPhase1TextChangeOpen(): boolean {
  return (
    isDevelopmentLevelEnv() &&
    localStorage.getItem(PRICING_PHASE_1_TEXT_CHANGE_IS_OPEN) === pricingPhase1TextChangeIsOpen
  )
}
export function enablePricingPhase1TextChange(): void {
  localStorage.setItem(PRICING_PHASE_1_TEXT_CHANGE_IS_OPEN, pricingPhase1TextChangeIsOpen)
}
export function disablePricingPhase1TextChange(): void {
  localStorage.removeItem(PRICING_PHASE_1_TEXT_CHANGE_IS_OPEN)
}

// Stripe 3DS対応
const STRIPE_3DS_IS_OPEN = 'stripe_3ds_is_open' as const
const stripe3dsIsOpen = 's-3-i-o' as const

export function isStripe3dsOpen(): boolean {
  return isDevelopmentLevelEnv() && localStorage.getItem(STRIPE_3DS_IS_OPEN) === stripe3dsIsOpen
}
export function enableStripe3ds(): void {
  localStorage.setItem(STRIPE_3DS_IS_OPEN, stripe3dsIsOpen)
}
export function disableStripe3ds(): void {
  localStorage.removeItem(STRIPE_3DS_IS_OPEN)
}
